import React from "react"
import { Link } from "gatsby"

/** @jsx jsx */
import { jsx } from 'theme-ui'

import Layout from "../components/layout"
import Container from "../components/container"
//import Image from "../components/image"
import SEO from "../components/seo"

const TheFood = () => (
  <Layout>
    <SEO title="The Food" />
    <Container>
      <h1>The Food</h1>
      <p>Dinner will be provided by the <a href="https://www.facebook.com/bangosbangosbangos/">Bango's food truck!</a></p>
      <Link to="/food/#menu" sx={{variant: 'text.inPageLink'}}>Menu | </Link>
      <Link to="/food/#bevs" sx={{variant: 'text.inPageLink'}}>Beverages | </Link>
      <Link to="/food/#diet" sx={{variant: 'text.inPageLink'}}>Dietary Restrictions</Link>
      <br/>
      <br/>
      <h2 id="menu">Menu</h2>
      <table>
        <tbody>
          <tr>
            <th colSpan="2">Entrees</th>
          </tr>
          <tr>
            <td>Spicy Chicken</td>
            <td>Fried chicken, iceberg, bang bang (spicy mayo), brioche.</td>
          </tr>
          <tr>
            <td>The Deluxe</td>
            <td>American cheeseburger, lettuce, tomato, onion, pickle, mayo, brioche.</td>
          </tr>
          <tr>
            <th colSpan="2">Sides</th>
          </tr>
          <tr>
            <td>Bang Bang Potato Salad</td>
            <td>Red potatoes, bang bang (spicy mayo), green onions.</td>
          </tr>
          <tr>
            <td>Peanut Sauce Cole Slaw</td>
            <td>Napa cabbage, red cabbage, green onion, Thai peanut sauce.</td>
          </tr>
        </tbody>
      </table>
      <h2 id="bevs">Beverages</h2>
      <table>
        <tbody>
          <tr>
            <td>Beer</td>
            <td>TBD</td>
          </tr>
          <tr>
            <td>Wine</td>
            <td>TBD</td>
          </tr>
          <tr>
            <td>Water</td>
            <td>...bottled</td>
          </tr>
        </tbody>
      </table>
      <h2 id="diet">Vegan, Gluten Free, and Others</h2>
      <p>To ensure that everyone can have a good time (and eat some good food)
      we are working to provide an option for those with dietary restrictions.</p>
      <p>At this time we have not confirmed what accomidations will be made, but
      will update this section when we know!</p>
      <p>If you have any specific questions about this, please feel free to reach
      out to either of us (Anna or Jacob) and we will do our best to answer!</p>
    </Container>
  </Layout>
)

export default TheFood
